import React from 'react';

export default function medium(props) {
	return (
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 300 300" >
		<rect x="126.7" y="190.9" className={props.logoFirst} width="41" height="5"/>
		<g>
			<path className={props.logoSecond} d="M149.57,182.65c-6.81,0.86-12.7-1.07-17.9-5.55c-4.43-3.82,0.59-5.2,1.87-7.36c1.95-3.31,3.29-0.78,4.63,0.84
				c0.42,0.5,0.99,0.88,1.48,1.31c3.35,0.66,6.69,0.51,10.04-0.01c2.7-2.03,3.02-5.09,3.07-8.04c0.17-11.28,0.11-22.57,0.25-33.85
				c0.03-2.36-0.76-3.36-3.15-3.14c-1.32,0.12-2.68-0.16-3.98,0.05c-3.89,0.63-4.67-1.14-4.66-4.72c0.01-3.5,1.06-4.52,4.52-4.42
				c9.29,0.25,18.58,0.14,27.87,0.17c2.15,2.81,2.06,5.74,0.48,8.75c-0.5,0.7-1.22,1.3-2.03,1.3c-5.24,0.03-5.86,3.2-5.91,7.57
				c-0.11,10.78,1.04,21.62-1.08,32.34C163.26,177.01,158.53,181.53,149.57,182.65z"/>
			<path className={props.logoFirst} d="M142.75,182.66c-6.8,0.9-12.7-1-17.93-5.45c-4.45-3.79,0.56-5.2,1.83-7.37c1.93-3.32,3.28-0.8,4.63,0.81
				c0.42,0.5,0.99,0.87,1.49,1.3c3.35,0.64,6.7,0.47,10.04-0.07c2.69-2.05,3-5.11,3.02-8.06c0.11-11.28-0.02-22.57,0.06-33.85
				c0.02-2.36-0.78-3.36-3.16-3.12c-1.32,0.13-2.68-0.14-3.98,0.07c-3.89,0.65-4.68-1.11-4.68-4.7c-0.01-3.5,1.03-4.52,4.49-4.45
				c9.29,0.2,18.58,0.04,27.87,0.02c2.16,2.8,2.09,5.73,0.52,8.75c-0.5,0.71-1.21,1.3-2.03,1.31c-5.24,0.06-5.84,3.23-5.87,7.6
				c-0.05,10.78,1.16,21.62-0.9,32.34C156.41,176.95,151.7,181.5,142.75,182.66z"/>
		</g>
		</svg>
  );
};