import React from 'react';

export default function small(props) {
	return (
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 60 60" >

		<rect x="13.7" y="53.2" className={props.logoFirst} width="27.4" height="3.3"/>
		<g>
			<path className={props.logoSecond} d="M29,47.7C24.4,48.3,20.5,47,17,44c-2.9-2.5,0.4-3.5,1.3-4.9c1.3-2.2,2.2-0.5,3.1,0.5c0.3,0.3,0.7,0.6,1,0.9
				c2.2,0.5,4.5,0.3,6.7,0c1.8-1.3,2-3.4,2.1-5.3c0.1-7.5,0.1-15.1,0.2-22.6c0-1.6-0.5-2.3-2.1-2.1c-0.9,0.1-1.8-0.1-2.7,0
				c-2.6,0.4-3.1-0.7-3.1-3.1c0-2.3,0.7-3,3-2.9C32.6,4.6,38.8,4.4,45,4.5c1.5,1.9,1.4,3.8,0.3,5.9c-0.3,0.5-0.8,0.9-1.3,0.9
				c-3.5,0-3.9,2.1-3.9,5.1c-0.1,7.2,0.7,14.4-0.7,21.6C38.1,44,35,47,29,47.7z"/>
			<path className={props.logoFirst} d="M24.4,47.7c-4.5,0.6-8.5-0.7-11.9-3.6c-3-2.5,0.4-3.5,1.2-4.9c1.3-2.2,2.2-0.5,3.1,0.5c0.3,0.3,0.7,0.6,1,0.9
				c2.2,0.4,4.5,0.3,6.7-0.1c1.8-1.4,2-3.4,2-5.4c0.1-7.5,0-15.1,0.1-22.6c0-1.6-0.5-2.3-2.1-2.1c-0.9,0.1-1.8-0.1-2.7,0.1
				c-2.6,0.4-3.1-0.7-3.1-3.1c0-2.3,0.7-3,3-3c6.2,0.1,12.4,0,18.6,0c1.5,1.9,1.4,3.8,0.3,5.9c-0.3,0.5-0.8,0.9-1.3,0.9
				c-3.5,0.1-3.9,2.1-3.9,5.1c0,7.2,0.8,14.4-0.6,21.6C33.6,43.9,30.4,46.9,24.4,47.7z"/>
		</g>
		</svg>

  	);
};